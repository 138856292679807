export const GET_CITY = "GET_CITY";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_ERROR = "GET_CITY_ERROR";

export const CITY_COMPARISON = "CITY_COMPARISON";
export const CITY_COMPARISON_SUCCESS = "CITY_COMPARISON_SUCCESS";
export const CITY_COMPARISON_ERROR = "CITY_COMPARISON_ERROR";

export const REMOVE_CITY = "REMOVE_CITY";
export const CLEAR_ALL_CITIES = "CLEAR_ALL_CITIES";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT = "LOGOUT";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

export const GET_FAVORITES = "GET_FAVORITES";
export const GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS";
export const GET_FAVORITES_ERROR = "GET_FAVORITES_ERROR";

export const ADD_FAVORITE = "ADD_FAVORITE";
export const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS";
export const ADD_FAVORITE_ERROR = "ADD_FAVORITE_ERROR";

export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const REMOVE_FAVORITE_SUCCESS = "REMOVE_FAVORITE_SUCCESS";
export const REMOVE_FAVORITE_ERROR = "REMOVE_FAVORITE_ERROR";
